<template>
  <body>
    <h2>Our Services</h2>
    <div id="container_intro" class="container_intro">
      <div>
        <h2>Consulting Services</h2>
        <p class='text-justify padding-top-2-rem'>
          A big part of our passion is to help our clients succeed. We provide an independent 
          look at the currently solved business challenges and suggest tailored solutions fueled 
          by the overview of the specific markets and existing solutions. We have experience with 
          creation of business and marketing strategies and also software product development 
          aiming at an optimal, sleek and efficient design.
        </p>
      </div>
    </div>
    <div class="container_intro_next">
      <div>
        <h2>Workshops for Business and Technical Analysis</h2>
        <p class='text-justify padding-top-2-rem'>
          Did you any time wonder about how to collect all the business requirements consistently 
          - across business units, roles, processes so that nothing is forgotten and collected in 
          a consistent and interlinked manner? If yes and still looking for a complete approach we 
          can offer a framework for requirement analysis and help to narrow down the business 
          processes, analyze the process steps involving any IT systems and for the missing parts 
          to be implemented to capture the business requirements that will later be converted into 
          technical tasks / user stories for implementation.
        </p>
      </div>
    </div>
    <div class="container_intro_next">
      <div>
        <h2>Software Solution Design</h2>
        <p class='text-justify padding-top-2-rem'>
          Based on the collected requirements we can prepare solution design involving data model, 
          integrations, permission setup and also set up foundation of the documentation for future 
          enhancements. With respect to clients' needs we aim for choosing the right and cost 
          effective tools.
        </p>
      </div>
    </div>
    <div class="container_intro_next">
      <div>
        <h2>Salesforce and Web Presence Implementation</h2>
        <p class='text-justify padding-top-2-rem'>
          We primarily focus on Salesforce.com and Web Presence implementations. Salesforce.com is a 
          perfect and scalable CRM solution providing many useful out of the box features and 
          doesn’t require any on-premise hardware setup and installations. Also basic implementations 
          are quick due to the extensive declarative nature. In case of higher customization needs, 
          Salesforce provides its programming language and complex automation capabilities.
        </p>
        <p class='text-justify'>
          Web Presence Implementations that we provide are based on VueJS framework. Due to this 
          fact, the web application can be very perfomant and efficiently running in a Single Page 
          Application mode. The advantage of this concept is client side rendering and execution 
          of the web app with almost immediate switches to pages within the web. All needed metadata 
          and data are loaded initially and only bigger pieces are then loaded on demand with 
          possibility of asynchronous operation - meaning the users don't need to wait for the 
          end of the execution to see a new page. These apps frovide fluid and lean experience.
        </p>
      </div>
    </div>
    <div class="container_intro_next last">
      <div>
        <h2>Trainings and Support in Salesforce.com</h2>
        <p class='text-justify padding-top-2-rem'>
          Whether planning to start a new project or having an existing one and just for the 
          need of user training, we can offer tailored trainings and eventual support and 
          consultations to help with your project progress. Would you like to get deeper into 
          a specific topic or have an outside view to a planned solution? Feel free to let us 
          know, we will be happy to help.
        </p>
      </div>
    </div>
  </body>
</template>

<script>
export default {
  name: 'Services'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 600px) {
    .main-nav {
        font-size: 0.5em;
        padding: 0;
    }

    .container_intro {
      display: flex;
      flex-direction: column !important;
      padding: 0 3% !important;
    }

    .container_intro_next {
      display: flex;
      flex-direction: column !important;
      padding: 0 3% !important;
    }

    .container_intro div p {
      text-align: justify;
    }

    .container_intro_next div p {
      text-align: justify;
    }
}

.main-nav {
    display: flex;
    list-style: none;
    margin: 0;
    font-size: 0.7em;
    background: rgb(230,230,233);
    background: linear-gradient(0deg, rgba(230,230,233,1) 0%, rgba(255,255,255,1) 16%, rgba(255,255,255,1) 100%);
}

.main-nav li {
    padding: 2em;
}

.main-nav li a {
    text-decoration: none;
    
}

.push {
    margin-left: auto;
}

.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
}

.sticky ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.header {
  width: 100%;
}

.container_cover {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background: url("/assets/coworkingspace.jpg") no-repeat center fixed;
    background-size: cover;
    background-attachment:scroll;
}

.container_intro {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
}

.container_intro div {
  margin: 6em 2em 2em 2em;
}

.container_intro_next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
}

.container_intro_next div {
  margin: 2em;
}

.last {
  margin-bottom: 6em !important;
}

.cover {
  max-height: 100vh;
  size: cover;
}

.coverText {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
}

.logo {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 10;
  transform: translate(-50%, -50%);
  max-width: 20%;
  border-radius: 20px;
  opacity: 80%;
}

.intro_pic {
  max-width: 60%;
  border-radius: 20px;
}

.text-justify {
  text-align: justify;
}

.padding-top-2-rem {
  padding-top: 2rem;
}

</style>
