<template>
  <div class="learn-more">
        <section id="header-down-arrow" class="header-down-arrow">
            <a href=""><img src="/assets/chevron.png" width="50"></a>
        </section>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    
    mounted: function () {
            $("#header-down-arrow").click(function(event) {
            console.log('GoToBottom clicked');
            event.preventDefault();
            $("html, body").animate({ 
                scrollTop: $("#container_intro").offset().top - 40
            }, "slow");
        });
    }
}
/*Learn more arrow END*/
</script>

<style>

.learn-more {
    color:white;
    border-color: coral;
}

.header-down-arrow {
    position: absolute;
    bottom: 4vh;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

@keyframes fade-slide-up {
    0% {
        opacity: 0;
        transform: translateY(4rem);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
        transform: none;
    }
    50% {
        opacity: .8;
        transform: scale(.8);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

.header-down-arrow img {
    animation: fade-slide-up 1s 1s ease-out forwards,
                pulse 2s 3s ease-out infinite;
    opacity: 0;
    background-color: hsla(0, 0%, 95%, 0.87);
    border-radius: 50px;
    padding: 10px;

    min-width: 10vh;
}

</style>