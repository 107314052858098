<template>
  <body>
    <div class="contact-form">
      <div class="form-intro">
        <h1>Questions and Requests</h1>
      </div>
      <contact-form/>
		</div>
</body>
</template>

<script>
import ContactForm from '@/components/forms/ContactForm.vue'

export default {
  components: {
    ContactForm
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 600px) {
    .main-nav {
        font-size: 0.5em;
        padding: 0;
    }

    .container_intro {
        padding: 0 !important;
    }

    .container_intro_next {
        padding: 0 !important;
    }
}

.main-nav {
    display: flex;
    list-style: none;
    margin: 0;
    font-size: 0.7em;
    background: rgb(230,230,233);
    background: linear-gradient(0deg, rgba(230,230,233,1) 0%, rgba(255,255,255,1) 16%, rgba(255,255,255,1) 100%);
}

.main-nav li {
    padding: 2em;
}

.main-nav li a {
    text-decoration: none;
    
}

.push {
    margin-left: auto;
}

.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
}

.sticky ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.header {
  width: 100%;
}

.container_cover {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background: url("/assets/coworkingspace.jpg") no-repeat center fixed;
    background-size: cover;
    background-attachment:scroll;
}

.container_intro {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
}

.container_intro div {
  margin: 6em 2em 2em 2em;
}

.container_intro_next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
}

.container_intro_next div {
  margin: 2em;
}

.last {
  margin-bottom: 6em !important;
}

.cover {
  max-height: 100vh;
  size: cover;
}

.coverText {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
}

.logo {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 10;
  transform: translate(-50%, -50%);
  max-width: 20%;
  border-radius: 20px;
  opacity: 80%;
}

.intro_pic {
  max-width: 60%;
  border-radius: 20px;
}

.contact-form {
  /* padding-bottom: 3em; */
  padding: 0 5% 3em 5%;
}

</style>
