<template>
  <div>
  <div class="container_cover">
      <img class="logo" src="/assets/SystemAidLogoTransparent.png">
      <div class="coverText"><h1>Here to aid you in system <br/>development and optimization</h1></div>
  </div>

  <div id="container_intro" v-bind:class="{ container_intro: !isMobile, container_intro_mobile : isMobile}">
    <div>
      <img v-if="!isMobile" class="intro_pic" src="/assets/brainstorming.svg">
    </div>
    <div>
      <p>
        SystemAid is a company focusing on provision of quality services in the areas of web application development, 
        CRM integrations and content management. Besides the development services we also provide consulting and are 
        eager to share the best practices and optimize existing or new solutions. 
      </p>
    </div>
  </div>
  <div v-bind:class="{ container_intro_next: !isMobile, container_intro_mobile : isMobile}">
    <div>
      <p>
        Our solutions and services are primarily based on progressive javascript framework VueJS in combination with 
        styling frameworks and integration services for communication to backend. Thanks to development of our own 
        applications we have experience and collection of best practices that can be applied on various projects.
      </p>
    </div>
    <div>
      <img v-if="!isMobile" class="intro_pic" src="/assets/web-programming.svg">
    </div>
  </div>
  <div class="last" v-bind:class="{ container_intro_next: !isMobile, container_intro_mobile : isMobile}">
    <div>
      <img v-if="!isMobile" class="intro_pic" src="/assets/loupe.svg">
    </div>
    <div>
      <p>
        Besides software development, we have also extensive experience with business process analysis, mapping and 
        further development. At scenarios when it is not totally clear which functionalities to implement or at which scale, 
        we can come in and organize workshops to identify the needs covering existing business processes.
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Home',
  data() { 
    return { 
      windowWidth: window.innerWidth
    } 
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 768
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 600px) {
  .main-nav {
    font-size: 0.5em;
    padding: 0;
  }

  .logo {
    position: absolute;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -40%) !important;
    max-width: 60% !important;
  }

  .coverText h1 {
    padding-top: 60%;
    font-size: 1.2em !important;
    font-weight: 500;
  }

  .container_intro {
    display: flex;
    flex-direction: column !important;
  }

  .container_intro_next {
    display: flex;
    flex-direction: column !important;
  }

  .container_intro div p {
    text-align: justify;
  }

  .container_intro_next div p {
    text-align: justify;
  }

  .last {
    margin-bottom: 2em !important;
  }
}

.main-nav {
  display: flex;
  list-style: none;
  margin: 0;
  font-size: 0.7em;
  background: rgb(230,230,233);
  background: linear-gradient(0deg, rgba(230,230,233,1) 0%, rgba(255,255,255,1) 16%, rgba(255,255,255,1) 100%);
}

.main-nav li {
  padding: 2em;
}

.main-nav li a {
  text-decoration: none;
}

.push {
  margin-left: auto;
}

.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
}

.sticky ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.header {
  width: 100%;
}

.container_cover {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  /* background: url("/assets/coworkingspace.jpg") no-repeat center fixed; */
  background: #363636;
  background-size: cover;
  background-attachment:scroll;
}

.container_intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
}

.container_intro div {
  margin: 6em 2em 2em 2em;
}

.container_intro_next {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
}

.container_intro_next div {
  margin: 2em;
}

.last {
  margin-bottom: 6em !important;
}

.cover {
  max-height: 100vh;
  size: cover;
}

.coverText {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  color: darkgray;
  font-weight:500;
  text-align: center;
}

.coverText h1 {
  padding-top: 60%;
  font-size: 1.4em;
}

.logo {
  position: absolute;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -20%);
  max-width: 300px;
}

.intro_pic {
  max-width: 60%;
}

.container_intro_mobile {
  padding: 10% 10% 0% 10%;
  text-align: justify;
}

</style>
