<template>
  <div id="app">
    <app-header/>

    <body>
      <router-view v-slot=" { Component } ">
        <transition
          :name="transitionName"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
        >
          <component :is="Component" />
        </transition>
      </router-view>
      <go-to-top/>
      <go-to-bottom v-if="this.isHome"/>
    </body>

    <app-footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue'
import Footer from '@/components/footer/Footer.vue'
import GoToTop from './components/navigation/GoToTop.vue'
import GoToBottom from './components/navigation/GoToBottom.vue'
// import { store } from './store/store.js'

const DEFAULT_TRANSITION = 'slide';

export default {
  name: 'App',
  components: {
    'app-header' : Header,
    'app-footer' : Footer,
    GoToTop,
    GoToBottom,
  },
  // store,
  data() {
    return {
      prevHeight: 2000,
      transitionName: DEFAULT_TRANSITION,
      isHome: true
    };
  },
  beforeMount() {
    this.isHome === true
  },
  created() {
    this.$router.beforeEach((to, from, next) => {

      // Var to define transition direction based on order of the route
      let directionalTransition;

      if(this.transitionName.includes('slide')) {
        const toValue = to.meta.order;
        const fromValue = from.meta.order;
        directionalTransition = toValue < fromValue ? 'slide-right' : 'slide-left';
      }

      this.transitionName = directionalTransition || DEFAULT_TRANSITION;

      next();
    });
  },
  watch: {
    $route (){
      // on route change, check if current route is Home
      // console.log('Route name: ' + this.$route.name);
      this.isHome = this.$route.name === null || this.$route.name === 'Homelanding' || this.$route.name === 'Home'
    }
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const {height} = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Zero margin to make all used components fullscreen */
body { margin: 0; }

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
