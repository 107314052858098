<template>
  <div>

  <div id="container_intro last" class="container_intro">
    <div>
      <img class="intro_pic" src="/assets/Radek_About1.png">
    </div>
    <div class="card_detail">
      <h3>Radek Holešinský</h3>
      <p>
      The founder of SystemAid Solutions with passion for software development and consulting. 
      Having years of experience as a Salesforce consultant and developer and also as web application 
      developer, I can offer complex services when tailoring your IT presence and will be happy 
      to share my tips & tricks to get to an ideal solution. In case of larger implementations 
      I can also maintain contact to other companies or CRM implementation partners.
      </p>

      <!-- <p>
      Besides software development I am also curious about medicine, literature, traveling and cooking. 
      Each of these is a kind of art, always inspiring.
      </p> -->
    </div>
  </div>
</div>
</template> 

<script>
export default {
  name: 'About'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 600px) {
    .main-nav {
        font-size: 0.5em;
        padding: 0;
    }

    .container_intro {
      display: flex;
      flex-direction: column !important;
      padding: 5% 5% 30% 5% !important;
      text-align: justify;
    }

    .container_intro h3{
      padding-bottom: 1em;
    }

    .container_intro_next {
      display: flex;
      flex-direction: column !important;
    }

    .container_intro div {
      margin: undefined !important;
    }

    .card_detail {
      margin: 2em 2em 2em 2em !important;
    }

    .card_detail h3 {
      text-align: center;
    }

    .container_intro div p {
      text-align: justify;
    }

    .container_intro_next div p {
      text-align: justify;
    }
}

.main-nav {
    display: flex;
    list-style: none;
    margin: 0;
    font-size: 0.7em;
    background: rgb(230,230,233);
    background: linear-gradient(0deg, rgba(230,230,233,1) 0%, rgba(255,255,255,1) 16%, rgba(255,255,255,1) 100%);
}

.about_text {
  min-height: 70vh !important;
}

.main-nav li {
    padding: 2em;
}

.main-nav li a {
    text-decoration: none;
    
}

.push {
    margin-left: auto;
}

.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
}

.sticky ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.header {
  width: 100%;
}

.container_cover {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background: url("/assets/coworkingspace.jpg") no-repeat center fixed;
    background-size: cover;
    background-attachment:scroll;
}

.container_intro {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 10% 30% 10%;
}

.container_intro div {
  margin: 6em 2em 2em 2em;
}

.container_intro_next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
}

.container_intro_next div {
  margin: 2em;
}

.last {
  margin-bottom: 20em !important;
}

.cover {
  max-height: 100vh;
  size: cover;
}

.coverText {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
}

.logo {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 10;
  transform: translate(-50%, -50%);
  max-width: 20%;
  border-radius: 20px;
  opacity: 80%;
}

.intro_pic {
  max-width: 160px;
  border-radius: 30%;
}


</style>
