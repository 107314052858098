<template>
    <form @submit.prevent="sendEmail">
        <div>
            <div v-show="!confirmation" class="col-md-8">
                <div>
                    <p class="contact-initial-text"> 
                        In case of any questions, requests for services or potential cooperation, please drop us a message here. 
                        We will be looking forward to get in touch with you.
                    </p>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-2" for="name">Name:</label>
                    <div class="col-sm-10">          
                        <input 
                            type="text"
                            v-model="name" required
                            class="form-control" 
                            id="name" 
                            placeholder="" 
                            name="name"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-2" for="email">Email:</label>
                    <div class="col-sm-10">
                        <input 
                            type="email"
                            v-model="email" required
                            class="form-control" 
                            id="email" 
                            placeholder="" 
                            name="email"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-2" for="message">Message:</label>
                    <div class="col-sm-10">
                        <textarea 
                            name="message"
                            class="form-control"
                            v-model="message" required
                            rows="5" 
                            id="message">
                        </textarea>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-10">
                        <p>
                            This site is protected by reCAPTCHA and the Google
                            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </p>
                        <button 
                            type="submit" 
                            class="btn btn-default" 
                            :class="{ 'submit-button-enabled': !isButtonDisabled}"
                            @click="confirmation = !confirmation"
                            :disabled ="isButtonDisabled">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div v-show="confirmation" class="confirm-block">
                <p class="confirm-text">Thank you for your request! We confirm that it has been received and will come back to you soon.</p>
                <button 
                    type="button" 
                    class="btn btn-success" 
                    @click="confirmation = !confirmation">
                    Next Request
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import emailjs from 'emailjs-com';
import { createApp } from 'vue';
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
import constants from '../../constants/constants';

const component = {
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const recaptcha = async () => {
        // (optional) Wait until recaptcha has been loaded.
        await recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        // const token = await executeRecaptcha('sendEmail')
        await executeRecaptcha('sendEmail')
    }

    return {
      recaptcha
    }
  },
  template: '<button @click="recaptcha">Execute recaptcha</button>'
}

createApp(component).use(VueReCaptcha, { siteKey: '6LcGI7kaAAAAAHVIoFlc9BIquU8zJMuGDRGcKza2' });

export default {
    name: 'ContactUs',
    data() {
        return {
        name: '',
        email: '',
        message: '',
        confirmation: false
        }
    },
    methods: {
        sendEmail(e) {
            try {
                emailjs.sendForm(
                    constants.SERVICEID_GMAIL, 
                    constants.TEMPLATEID_CONTACTFORM, 
                    e.target,
                    constants.USERID_CONTACTFORM, 
                    {
                    name: this.name,
                    email: this.email,
                    message: this.message
                    }
                )

            } catch(error) {
                console.log({error})
            }
            // Reset form field
            this.name = ''
            this.email = ''
            this.message = ''
        },
    },
    computed: {
        isButtonDisabled() {
            return(
                this.email === null || this.email === '' || 
                this.name === null || this.name === '' ||
                this.message === null || this.message === ''
            );
        }
    },
}
</script>

<style>
.grecaptcha-badge { 
    visibility: hidden;
}

.contact-form button{
    background: hsl(237, 35%, 22%);
    color: #fff;
    font-weight: 600;
    align-content: center;
    text-align: center;
}

.form-intro {
    padding-top: 6em;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
}

.form-intro h1{
    text-align: center;
}

.form-intro p{
    text-align: justify;
    padding: 1em 2em;
}

.confirm-block {
    text-align: center;
}

.confirm-text {
    padding: 3em 2em;
    text-align: center;
}

.contact-initial-text {
    text-align: justify;
    padding: 2em 1em;
    font-size: medium;
    text-align: justify;
}

.submit-button-enabled {
    background: #0255a3 !important;
}

.submit-button-enabled:focus{
    box-shadow:none;
    color: lightgray;
}

.submit-button-enabled:hover {
    background: #023668 !important;
    box-shadow:none;
    color: lightgray !important;
}
</style>