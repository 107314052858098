import { createWebHistory, createRouter } from "vue-router";

// definition of routes to be used
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Services from '@/views/Services.vue';
import Contact from '@/views/Contact.vue';

const routes = [
    { 
        path: '/',
        alias: ['/home'],
        name: 'Home',
        component: Home,
        meta: {
            order: 0
        },
    },
    { 
        path: '/about', 
        name: 'About',
        component: About,
        meta: {
            order: 1
        },
    },
    { 
        path: '/services', 
        name: 'Services',
        component: Services,
        meta: {
            order: 2
        },
    },
    { 
        path: '/contact', 
        name: 'Contact',
        component: Contact,
        meta: {
            order: 3
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || new Promise((resolve)=>{
            setTimeout(()=> resolve({ top: 0, behavior: 'smooth'}), 400)
        })
    }
  });
  
  export default router;