<template>
  <header>
    <nav class="sticky">
      <Slide id="burger-menu">
        <router-link class="burger-menu-item" to="/home" :class="{ 'route-selected' : this.$route.name === 'Home'}">Home</router-link>
        <router-link class="burger-menu-item" to="/about" :class="{ 'route-selected' : this.$route.name === 'About'}">About</router-link>
        <router-link class="burger-menu-item" to="/services" :class="{ 'route-selected' : this.$route.name === 'Services'}">Services</router-link>
        <router-link class="burger-menu-item" to="/contact" :class="{ 'route-selected' : this.$route.name === 'Contact'}">Contact</router-link>
      </Slide>
      <ul class="main-nav">
        <li><router-link class="nav-menu-item" to="/home" :class="{ 'route-selected' : this.$route.name === 'Home'}">Home</router-link></li>
        <li><router-link class="nav-menu-item" to="/about" :class="{ 'route-selected' : this.$route.name === 'About'}">About</router-link></li>
        <li><router-link class="nav-menu-item" to="/services" :class="{ 'route-selected' : this.$route.name === 'Services'}">Services</router-link></li>
        <li><router-link class="nav-menu-item" to="/contact" :class="{ 'route-selected' : this.$route.name === 'Contact'}">Contact</router-link></li>
      </ul>
      <router-link to="/home"><img class="nav_pic" src="/assets/website_logo_transparent_icon.png"></router-link>
    </nav>
  </header>
</template>

<script>
import { Slide } from 'vue3-burger-menu' // imported transition effect for burger menu

export default {
  components: {
    Slide
  },
  watch: {
    $route () {
      // on route change, close the burger menu by a regular means to let the transitions have effect
      document.querySelector('.bm-cross').click();
    }
  },
}
</script>

<style>
@media only screen and (max-width: 600px) {
  .main-nav {
    font-size: 0.5em;
    padding: 0;
  }

  #burger-menu {
    visibility: visible !important;
  }

  .main-nav li {
    visibility: hidden !important;
  }

  .bm-cross-button {
    padding: 1em 2em 1em 1em !important;
  }

  .nav_pic {
    max-width: 20% !important;
    margin-left: auto !important;
    position: fixed;
    right: 1.5em !important;
    top: .7em;
  }

  .burger-menu-item {
    text-decoration: none !important;
  }
}

.nav_pic {
  max-height: 50%;
  max-width: 70px;
  width: auto;
  margin-left: auto !important;
  right: 3%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#burger-menu {
  visibility: hidden;
}

.burger-menu-item {
  margin-left: 10px;
  font-weight: 700;
  color: #fff;
}

.nav-menu-item {
  margin-left: 10px;
  font-weight: 700;
  color: #363636;
}

.bm-burger-button {
  margin: 1em;
  left: 5px !important;
  top: 5px !important;
}

.bm-menu {
  width: 250px;
}

.main-nav {
  display: flex;
  list-style: none;
  margin: 0;
  font-size: 0.8em;
  background: rgb(230,230,233);
  background: linear-gradient(0deg, rgba(230,230,233,1) 0%, rgba(255,255,255,1) 16%, rgba(255,255,255,1) 100%);
}

.main-nav li {
  padding: 2em;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
}

.main-nav li a {
  text-decoration: none;
}

.push {
  margin-left: auto !important;
}

.sticky {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
}

.sticky ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.header {
  width: 100%;
}

.route-selected {
  color: #2a7fcf !important;
}
</style>